import Access from "./components/Access";

function App() {

  return (
    <div>
        <Access />
    </div>
  );
}

export default App;
